import { TrackerManager } from './lib/tracker-manager';
import { loadFingerPrintManagerModule, loadIdentifyServiceModule, loadTrackEnqueueModule } from './lib//utils';


void (async function () {
  try {
    await loadTrackEnqueueModule();
    await loadIdentifyServiceModule();
    await loadFingerPrintManagerModule();

    customElements.define('rai-tracker', TrackerManager);
  } catch (error) {
    console.error(error);
  }
})()
