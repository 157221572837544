import { LitElement, PropertyValues, html } from "lit"
import { property, state } from 'lit/decorators.js'
import { Subject, filter, takeUntil } from "rxjs"
import { Trackers, TrackingSystems } from "./models"
import { getPolicyManager } from "./utils"

const unsubscribe$ = new Subject<void>()

export class TrackerManager extends LitElement {
  @property({ type: Object })
  declare pageInfo: PageInfo | undefined
  @property({ type: Boolean })
  declare webtrekk: boolean
  @property({ type: Boolean })
  declare comscore: boolean
  @property({ type: Boolean })
  declare nielsen: boolean
  @property({ type: Boolean })
  declare akamai: boolean
  @property({ type: Boolean })
  declare exaudi: boolean
  @property({ type: Boolean })
  declare railytics: boolean
  @property()
  declare disableOnLoadTrack: string
  @property()
  declare disableTrackingSystems: TrackingSystems
  @property()
  declare mode: string

  @state()
  declare private exaudiLoaded: boolean
  @state()
  declare private webtrekkLoaded: boolean
  @state()
  declare private comsoreLoaded: boolean
  @state()
  declare private nielsenLoaded: boolean
  @state()
  declare private akamaiLoaded: boolean
  @state()
  declare private railyticsLoaded: boolean

  constructor() {
    super()

    this.pageInfo = undefined
    this.webtrekk = true
    this.comscore = true
    this.nielsen = true
    this.akamai = true
    this.exaudi = true
    this.railytics = true
    this.disableOnLoadTrack = ''
    this.disableTrackingSystems = ''
    this.mode = ''

    this.webtrekkLoaded = false
    this.comsoreLoaded = false
    this.akamaiLoaded = false
    this.nielsenLoaded = false
    this.exaudiLoaded = false
    this.railyticsLoaded = false
  }

  private get trackEnqueue() {
    return window.Rai!._trackEnqueue
  }

  override connectedCallback(): void {
    super.connectedCallback()

    const pageInfoEl = this.querySelector<HTMLElement>('#page_info')
    if (pageInfoEl) {
      this.pageInfo = JSON.parse(pageInfoEl.innerText) as PageInfo
    }

    this.dispatch('tracker_ready', undefined)
    if (this.disableTrackingSystems) {
      this.disableTrackingSystems.split(',').forEach((t) => {
        const tracker = t.trim() as Trackers
        this[tracker] = false
      })
    }

    this.trackEnqueue.policyActivator.pipe(
      filter(Boolean),
      takeUntil(unsubscribe$),
    ).subscribe(() => { this.initGDPRTrackingSystems() })

    this.trackEnqueue.privacyPolicyActivator.pipe(
      filter(Boolean),
      takeUntil(unsubscribe$),
    ).subscribe(() => { this.initPrivacyPolicyTrackingSystems().catch(console.error) })

    this.initTrackingSystems()
  }

  private initTrackingSystems() {
    if (this.webtrekk) {
      const u = new URL(__public_path__ + 'rai-webtrekk.js', window.location.href)
      import(/* @vite-ignore */u.toString())
        .then(() => customElements.whenDefined('rai-webtrekk'))
        .then(() => { this.webtrekkLoaded = true })
        .catch(console.error)
    }
    if (this.akamai) {
      const u = new URL(__public_path__ + 'rai-akamai.js', window.location.href)
      import(/* @vite-ignore */u.toString())
        .then(() => customElements.whenDefined('rai-akamai'))
        .then(() => { this.akamaiLoaded = true })
        .catch(console.error)
    }
    if (this.comscore) {
      const u = new URL(__public_path__ + 'rai-comscore.js', window.location.href)
      import(/* @vite-ignore */u.toString())
        .then(() => customElements.whenDefined('rai-comscore'))
        .then(() => { this.comsoreLoaded = true })
        .catch(console.error)
    }
    if (this.nielsen) {
      const u = new URL(__public_path__ + 'rai-nielsen.js', window.location.href)
      import(/* @vite-ignore */u.toString())
        .then(() => customElements.whenDefined('rai-nielsen'))
        .then(() => { this.nielsenLoaded = true })
        .catch(console.error)
    }
    if (this.railytics) {
      const u = new URL(__public_path__ + 'rai-railytics.js', window.location.href)
      import(/* @vite-ignore */u.toString())
        .then(() => customElements.whenDefined('rai-railytics'))
        .then(() => { this.railyticsLoaded = true })
        .catch(console.error)
    }
  }

  private async initPrivacyPolicyTrackingSystems() {
    const policyManager = await getPolicyManager();
    if (this.exaudi && policyManager.hasConsents(['1', '2', '3', '4', '7', '9'])) {
      try {
        const u = new URL(__public_path__ + 'rai-exaudi.js', window.location.href)
        import(/* @vite-ignore */u.toString())
          .then(() => customElements.whenDefined('rai-exaudi'))
          .then(() => { this.exaudiLoaded = true })
          .catch(console.error)
      } catch (e) {
        console.error(e)
      }
    }
  }

  private initGDPRTrackingSystems() { /* empty */ }

  protected override firstUpdated(_changedProperties: PropertyValues<this>): void {
    super.firstUpdated(_changedProperties)

    if (this.disableOnLoadTrack !== 'true' && this.mode !== 'extendInfoByComponent') {
      this.trackEnqueue.setPageQueueEvent(this.pageInfo!)
    }
    this.trackEnqueue.setUserQueueEvent(this.pageInfo!)
  }

  protected override render(): unknown {
    return html`
      <slot></slot>
      ${this.webtrekkLoaded ? html`<rai-webtrekk .mode=${this.mode} .pageInfoBaseData=${this.pageInfo}></rai-webtrekk>` : ''}
      ${this.comsoreLoaded ? html`<rai-comscore .mode=${this.mode} .pageInfoBaseData=${this.pageInfo}></rai-comscore>` : ''}
      ${this.nielsenLoaded ? html`<rai-nielsen .mode=${this.mode} .pageInfoBaseData=${this.pageInfo}></rai-nielsen>` : ''}
      ${this.akamaiLoaded ? html`<rai-akamai></rai-akamai>` : ''}
      ${this.exaudiLoaded ? html`<rai-exaudi .pageInfoBaseData=${this.pageInfo}></rai-exaudi>` : ''}
      ${this.railyticsLoaded ? html`<rai-railytics .mode=${this.mode} .pageInfoBaseData=${this.pageInfo}></rai-railytics>` : ''}
    `
  }

  override disconnectedCallback(): void {
    super.disconnectedCallback()
    unsubscribe$.next()
    unsubscribe$.complete()
  }


  private dispatch<T extends keyof HTMLElementEventMap>(type: T, detail: CustomEventDetail<T>) {
    const event = detail
      ? new CustomEvent(type, { detail, bubbles: true, composed: true, cancelable: true })
      : new Event(type, { bubbles: true, composed: true, cancelable: true })
    this.dispatchEvent(event)
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'rai-tracker': TrackerManager
  }

  interface HTMLElementEventMap {
    'tracker_ready': Event
  }
}

type CustomEventDetail<T extends keyof HTMLElementEventMap> =
  HTMLElementEventMap[T] extends CustomEvent<infer R>
    ? R
    : undefined
