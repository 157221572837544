import { FingerPrintManager } from '@rai-analytics/rai-fingerprint';
import { IdentifierService } from '@rai-analytics/rai-identifier-service';


export async function loadTrackEnqueueModule(): Promise<ITrackEnqueue> {
  return new Promise((resolve) => {
    if (window.Rai?._trackEnqueue) {
      return resolve(window.Rai?._trackEnqueue);
    } else {
      const u = new URL(__public_path__ + 'track-enqueue.js', window.location.href)
      import(/* @vite-ignore */u.toString()).then(() => {
        setTimeout(async () => {
          await getTrackEnqueue();
          resolve(window.Rai!._trackEnqueue);
        });
      }).catch(console.error);
    }
  });
}


export async function loadFingerPrintManagerModule(): Promise<FingerPrintManager> {
  return new Promise((resolve) => {
    if (window.Rai?.FingerPrintManager) {
      return resolve(window.Rai?.FingerPrintManager);
    } else {
      const u = new URL(__public_path__ + 'rai-fingerprint.js', window.location.href)
      import(/* @vite-ignore */u.toString()).then(() => {
        setTimeout(async () => {
          await getFingerPrintManager();
          resolve(window.Rai!.FingerPrintManager);
        });
      }).catch(console.error);
    }
  });
}


export async function loadIdentifyServiceModule(): Promise<IdentifierService> {
  return new Promise((resolve) => {
    if (window.Rai?.IdentifierService) {
      return resolve(window.Rai?.IdentifierService);
    } else {
      const u = new URL(__public_path__ + 'rai-identifier-service.js', window.location.href)
      import(/* @vite-ignore */u.toString()).then(() => {
        setTimeout(() => {
          resolve(window.Rai!.IdentifierService);
        });
      }).catch(console.error);
    }
  });
}

function getTrackEnqueue() {
  return new Promise<ITrackEnqueue>(resolve => {
    if (window.Rai?._trackEnqueue) {
      resolve(window.Rai._trackEnqueue);
    } else {
      const origObj = window.Rai ?? {};
      window.Rai = new Proxy(origObj, {
        set(target, prop, value) {
          const setted = Reflect.set(target, prop, value);
          if (prop === '_trackEnqueue') {
            window.Rai = origObj;
            window.setTimeout(() => resolve(window.Rai._trackEnqueue), 0);
          }
          return setted;
        },
      });
    }
  });
}

function getFingerPrintManager() {
  return new Promise<FingerPrintManager>(resolve => {
    if (window.Rai?.FingerPrintManager) {
      resolve(window.Rai.FingerPrintManager);
    } else {
      const origObj = window.Rai ?? {};
      window.Rai = new Proxy(origObj, {
        set(target, prop, value) {
          const setted = Reflect.set(target, prop, value);
          if (prop === 'FingerPrintManager') {
            window.Rai = origObj;
            window.setTimeout(() => resolve(window.Rai.FingerPrintManager), 0);
          }
          return setted;
        },
      });
    }
  });
}

export async function getPolicyManager(): Promise<PolicyManager> {
  return new Promise<PolicyManager>((resolve) => {
    if (window.Rai?.PolicyManager) {
      return resolve(window.Rai.PolicyManager);
    }

    const originalRai = window.Rai ?? {} as Rai;
    window.Rai = new Proxy(originalRai, {
      set: (target, p, newValue, receiver) => {
        const setted = Reflect.set(target, p, newValue, receiver)
        if (p === 'PolicyManager') {
          window.Rai = originalRai
          window.setTimeout(() => {
            resolve(newValue as PolicyManager);
          })
        }
        return setted;
      }
    })
  });
}
